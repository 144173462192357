@import "mixins";
@import "custom";
@import "variables";
@import "~bootstrap/scss/bootstrap";

p {
	margin-bottom: 0;
}
span {
	display: block;
}

.container {
	@media screen and (max-width: 767px) {
		margin-left: 4%;
	}
	@media (min-width: 768px) and (max-width: 1919px) {
		margin-left: 6.2%;
	}
}

.header {
	margin-top: 9%;
	color: #1A1A1A;
	margin-bottom: 6.3em;
	overflow: hidden;
	@media (min-width: 768px) {
		margin-top: 4.2%;
		overflow: visible;
	}

	&__blocks {
		display: flex;
		align-items: center;
		//justify-content: space-between;
		justify-content: center;
		margin-bottom: .9em;
		margin-top: 7em;
		@media (min-width: 768px) {
			justify-content: space-between;
			margin-top: 0;
		}
	}

	&__title {
		display: flex;
		flex-direction: column;

		&-name {
			position: relative;
			@include bold-link;
			line-height: 0.55;
			font-size: 5.55em;
			@media (min-width: 768px) {
				font-size: 4.28em;
			}

			&_img {
				width: 12.6em;
			}
		}

		&-ava {
			display: none;
			position: absolute;
			top: -2%;
			left: -10%;
			//top: -.23em;
			//left: -1.23em;

			@media (min-width: 768px) {
				display: inline-block;
			}
			&_img {
				width: .83em;
				//@media (min-width: 1260px) and (max-width: 1919px) {
				//	width: 75%;
				//}
				//@media (min-width: 768px) and (max-width: 1259px) {
				//	width: 40%;
				//}
				//@media screen and (max-width: 767px) {
				//	width: 20%;
				//}
			}
		}

		&-en {
			top: 10%;
			left: -10%;
		}
	}

	&__links {
		display: none;
		padding-top: 2.2%;
		@include light-text;
		//font-weight: 400;
		//font-size: 1.3125em;
		@media (min-width: 768px) {
			display: block;
			padding-top: 1em;
		}
		.link-active {
			margin-right: .8em;

			&:last-child {
				margin-right: 0;
			}
		}
		.link-lang {
			margin-left: 1em;
			margin-right: 0;
		}
	}

	&__desc {
		margin-top: .3em;
		@include mob-bold-link;
		text-align: center;

		@media (min-width: 768px) and (max-width: 1259px) {
			margin-top: 0;
			font-size: 2.2em;
			line-height: 1rem;

			text-align: left;
		}

		@media (min-width: 1260px) {
			margin-top: 0;
			font-size: 2.2em;
			line-height: 1.5rem;

			text-align: left;
		}
	}
}

.en-page {
	@media (min-width: 768px) {
		margin-top: 3.8%;
	}
}

.row-0 {
	padding-bottom: 5.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 9em;
		padding-top: 1.5em;
		overflow: visible;
	}
	.prosto {
		position: relative;
		z-index: 50;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-top: 14.5%;
			margin-left: -.5%;
			margin-right: -.5%;

			background-color: #fff;
			box-shadow: 0 0 50px 15px #ffffff;
			@media (min-width: 768px) {
				margin-top: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}

		&__link {
			margin-top: 0.6em;
			@include mob-bold-link;
			text-align: left;

			@media (min-width: 768px) {
				margin-top: 2.5%;
				@include bold-link;
			}
		}
	}

	.redesign {
		position: relative;
		z-index: 50;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -.5%;
			margin-right: -.5%;
			margin-top: 9.8%;

			@media (min-width: 768px) {
				margin-left: 1%;
				margin-right: -12.5%;
				margin-top: 0;
			}

		}

		&__link {
			margin-top: 0.6em;
			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				margin-top: 8%;
				margin-left: 0;
				@include normal-link;
				line-height: 1.165;
				letter-spacing: 0.03em;
				text-align: left;
			}
		}
	}
}

.row-1 {
	padding-bottom: 15em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 8.5em;
		overflow: visible;
	}

	.video {
		position: relative;
		z-index: 50;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-top: 10em;
			margin-left: -4.3%;
			margin-right: -4.5%;

			background-color: #fff;
			box-shadow: 0 0 50px 15px #ffffff;
			@media (min-width: 768px) {
				margin-top: 0;
				margin-left: -3.8%;
				margin-right: -7.3%;
			}
		}

		&__link {
			margin-top: -0.3em;
			@include mob-bold-link;
			text-align: left;

			@media (min-width: 768px) {
				margin-top: -3.3%;
				@include bold-link;
			}
		}
	}

	.travel {
		position: relative;
		z-index: 50;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -0.8%;
			margin-right: -1.2%;
			margin-top: 14.3%;

			@media (min-width: 768px) {
				margin-left: -6.5%;
				margin-right: 6.5%;
				margin-top: 1.3%;
			}

		}

		&__link {
			margin-top: .5em;
			@include mob-bold-link;

			@media (min-width: 768px) {
				margin-top: -4.5%;
				margin-left: -14%;
				@include bold-link;
				text-align: center;
			}
		}
	}
}

.row-2 {
	padding-bottom: 11.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 10em;
		overflow: visible;
	}

	.respect {
		position: relative;
		z-index: 3;
		@media (min-width: 768px) {

		}

		&__img {
			margin-left: -5.5%;
			margin-right: -6%;
			margin-bottom: -2%;

			@media (min-width: 768px) {
				margin-left: -4.7%;
				margin-right: -7%;
				margin-bottom: 0;
			}

		}

		&__desc {
			@include mob-desc-text;

			@media (min-width: 768px) {
				margin-top: -4.1%;
				@include light-text;
				text-align: center;
			}


		}
	}

	.meso-admin {
		z-index: 2;
		position: relative;


		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			z-index: -1;
			position: relative;
			margin-top: -31.7%;
			transform: rotate(
					12.5deg);
			margin-left: -15.7%;
			margin-right: -11%;
			@media (min-width: 768px) {
				margin-top: -110.3%;
				margin-left: -16.5%;
				//margin-right: -22.68%;
				margin-right: -23.68%;
				transform: none;
			}

			&:before {
				content: none;
				@media (min-width: 768px) {
					content: '';
					display: block;
					height: 7%;
					right: 0;
					position: absolute;
					top: 0;
					width: 5%;
					background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
				}
			}
		}

		&__link {
			z-index: 2;
			margin-top: -6.5em;
			margin-left: 0;

			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: 31px;
			letter-spacing: 0.01em;
			text-align: left;

			@media (min-width: 768px) {
				z-index: 50;
				margin-top: -46.4%;
				margin-left: 0;
				@include normal-link;
				line-height: 1.165;
				letter-spacing: 0.03em;
			}
		}
	}
}

.row-3 {
	overflow: hidden;
	padding-bottom: 19em;
	@media (min-width: 768px) {
		overflow: visible;
		padding-bottom: 0;
	}

	.kissing {
		z-index: 48;
		position: relative;
		margin-bottom: 15em;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}

		&__img {
			margin-left: -8.1%;
			margin-right: -8.7%;
		}

		&__desc {
			z-index: 1;
			margin-top: -6.2%;
			@include mob-desc-text;

			@media (min-width: 768px) {
				margin-top: -7.2%;
				@include light-text;
				text-align: center;
			}
		}
	}

	.menu {
		z-index: 1;
		position: relative;
		margin-top: -1%;
		margin-left: -8.2%;
		margin-right: -0.2%;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-right: -8.4%;

			@media (min-width: 768px) {
				margin-right: -7.4%;
			}
		}

		&__link {
			@include mob-bold-link;
			margin-left: 7.8%;
			margin-top: -7.5%;


			@media (min-width: 768px) {
				@include bold-link;
				margin-top: -8.9%;
				text-align: left;
			}
		}
	}
}

.row-4 {
	padding-bottom: 7.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		margin-top: -18em;
		overflow: visible;
	}

	.meso-app {
		z-index: 47;
		position: relative;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -1.6%;
			margin-right: -1.6%;
		}

		&__link {
			@include mob-bold-link;
			margin-top: 2%;
			width: 120%;

			@media (min-width: 768px) {
				@include normal-link;
				text-align: left;
				width: 100%;
			}
		}
	}
}

.row-5 {
	z-index: 46;
	position: relative;
	padding-bottom: 18.7em;
	overflow: hidden;
	@media (min-width: 768px) {
		overflow: visible;
		padding-bottom: 7.7em;
	}

	.photoshop {
		display: none;
		@media (min-width: 768px) {
			display: block;
		}

		&__img {
			position: relative;
			margin-left: -5%;
			margin-right: -7.7%;

			&-eyes {
				position: absolute;
				top: 49.7%;
				left: -4.1%;
				width: 5%;
				transform: rotateY(180deg);
			}

			&:before {
				content: '';
				z-index: 2;
				position: absolute;

				background-size: 20%;
				top: 53.6%;
				left: 2%;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-image: url(../img/line.svg);
			}
		}

		&__desc {
			z-index: 2;
			position: relative;

			padding-top: 3.5%;
			border-top: 1px solid #000;
			@include light-text;
			text-align: center;
		}
	}

	.present {
		position: relative;
		margin-top: 5.5%;
		@media (min-width: 768px) {
			margin-top: -20.5%;
		}

		&:before {
			content: none;
			@media (min-width: 768px) {
				content: '';
				z-index: 2;
				position: absolute;
				bottom: 0.5%;
				right: 0;
				width: 9.4em;
				//width: 132px;
				height: 30px;
				background-size: 100%;
				background-repeat: no-repeat;
				background-image: url(../img/kaspersky-logo.svg);
			}
		}

		&:hover .link-active {
			@include link-hover;
		}

		&__img {

			transform: rotate(90deg);
			margin-top: 11em;
			margin-left: 0;
			margin-bottom: 87em;

			@media (min-width: 768px) {
				transform: rotate(0deg);
				margin-right: 2.1%;
				margin-top: 0;
				margin-left: 0;
				margin-bottom: 0;
			}

			.img-link {
				img {
					width: 205em;
					height: 126em;
					@media (min-width: 768px) {
						width: 100%;
						height: auto;
					}
				}
			}
		}

		&__link {
			@include mob-bold-link;
			margin-top: 0;

			@media (min-width: 768px) {
				margin-top: -8.8%;

				font-size: 3.6em;
				font-weight: 700;
				line-height: 1.2;
				letter-spacing: 0.01em;
				text-align: left;
				color: #1A1A1A;
			}

			.br-mob {
				display: none;
				@media (min-width: 768px) {
					display: block;
				}
			}
		}
	}
}

.row-6 {
	z-index: 45;
	position: relative;
	//padding-bottom: 18.5em;
	overflow-x: auto;
	overflow-y: hidden;
	@media (min-width: 768px) {
		padding-bottom: 10.5em;
		overflow: visible;
	}

	.neck {
		position: relative;

		//padding-bottom: 10em;
		&::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
			display: none !important;
			background: transparent;
		}
		@media (min-width: 768px) {
			overflow: visible;
			padding-bottom: 0;
		}
		.br-mob {
			display: none;
			@media (min-width: 768px) {
				display: block;
			}

		}
		&__img {
			margin-left: 0;
			margin-right: -169%;
			padding-right: 6em;
			background-color: #fafafa;
			box-shadow: 0 0 50px 0 #fafafa;

			@media (min-width: 768px) {
				padding-right: 0;
				margin-left: -7.7%;
				margin-right: -7.7%;
			}
		}

		&__desc {
			margin-top: 1em;
			position: sticky;
			left: 1em;
			bottom: 0;
			@include mob-desc-text;

			@media (min-width: 768px) {
				position: initial;
				margin-top: -5.4%;
				@include light-text;

				text-align: center;
			}
		}
	}
}

.signature__main {
	padding-bottom: 18.5em;
	padding-top: 5em;
	@media (min-width: 768px) {
		padding: 0;
	}
}

.row-7 {
	padding-bottom: 9.1em;
	overflow: hidden;
	@media (min-width: 768px) {
		overflow: visible;
	}
	.bks-land {
		margin-bottom: 18em;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}

		&:hover .link-active {
			@include link-hover;
		}

		.bsk-land__info {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
		}

		&__img {
			margin-bottom: 5em;
			@media (min-width: 768px) {
				margin-bottom: 0;
			}
		}

		&__link {
			@include mob-bold-link;

			@media (min-width: 768px) {
				margin-top: 0.43em;
				@include bold-link;
				text-align: left;
			}

		}

		&__time {
			display: none;
			@media (min-width: 768px) {
				display: block;
			}

			&-hour {
				padding-bottom: 2%;

				@include bold-link;
				text-align: left;

				color: #0072CD;

			}

			&-middle {
				@include light-text;
				font-weight: 400;
				text-align: right;

			}
		}
	}

	.bks-mob {
		z-index: 44;
		position: relative;
		margin-top: -2.5%;
		margin-left: -5%;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-right: -8em;
			margin-left: -2em;
			margin-bottom: -6em;
			@media (min-width: 768px) {
				margin-left: 0;
				margin-right: 11%;
				margin-bottom: 0;
			}
		}

		&__link {
			margin-left: 4.5%;
			width: 110%;
			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include normal-link;
			}
		}
	}
}

.row-8 {
	padding-bottom: 14.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 8.5em;
		overflow: visible;
	}

	.bks-invest {
		z-index: 43;
		position: relative;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -9%;
			margin-right: -8%;
		}

		&__link {
			margin-top: -8%;

			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include normal-link;
			}
		}
	}

	.stamp {
		z-index: 43;
		position: relative;

		&__img {
			margin-top: 15%;
			margin-right: 9%;
			margin-left: 11%;
			@media (min-width: 768px) {
				margin-top: 5%;
				margin-left: 17%;
				margin-right: 18.5%;
			}
		}

		&__desc {
			margin: 6% auto 0;
			width: 84%;
			@media (min-width: 768px) {
				margin-top: 6%;
				//margin-left: -14.4%;
				margin-left: auto;
				//width: 110%;
			}

			@include mob-desc-text;
			@media (min-width: 768px) {
				@include light-text;
				text-align: center;
			}
		}
	}

	.app-auto {
		z-index: 43;
		position: relative;
		margin-top: 14%;
		margin-left: 0;
		@media (min-width: 768px) {
			margin-top: -45%;
		}

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-right: 0;
			@media (min-width: 768px) {
				margin-right: -10%;
			}
		}

		&__link {
			margin-top: 5%;
			width: 100%;

			@media (min-width: 768px) {
				margin-top: 7%;
				width: 115%;
			}

			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include normal-link;
			}
		}
	}
}

.row-9 {
	position: relative;
	margin-top: 0;
	padding-bottom: 10.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		margin-top: -48em;
		overflow: visible;
	}

	.frontol-app {
		z-index: 42;
		position: relative;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -8.2%;
			margin-right: -9.2%;
			@media (min-width: 768px) {
				margin-left: -7.2%;
				margin-right: -7.2%;
			}
		}

		&__link {
			margin-top: -6.2%;
			@media (min-width: 768px) {
				margin-top: -8.2%;
			}

			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include bold-link;
			}
		}
	}
}

.row-10 {
	z-index: 41;
	padding-bottom: 20em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 7em;
		overflow: visible;
	}
	.case {
		margin-top: 8%;
		margin-bottom: 15%;
		@media (min-width: 768px) {
			margin-top: -66%;
			margin-bottom: 0;
		}
		&__img {
			margin-bottom: 4.5%;

		}

		&__link {
			@include mob-desc-text;
			@media (min-width: 768px) {
				@include light-text;
				text-align: center;
			}

			p:last-child {
				margin-top: 3%;
			}
		}
	}

	.frontol-illust {
		position: relative;

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			display: flex;
			flex-direction: column;
			@media (min-width: 768px) {
				flex-direction: row;
			}
			.illust-links {
				&:first-child {

					margin-top: 2%;
					order: 1;
					@media (min-width: 768px) {
						margin-top: 0;
						margin-right: 10px;
						order: 0;
					}
				}
			}
		}

		&__link {
			margin-top: 4.5%;
			width: 100%;
			@include mob-bold-link;
			text-align: left;

			@media (min-width: 768px) {
				@include bold-link;
				margin-top: 2.5%;
				width: 110%;
			}
		}
	}
}

.row-11 {
	z-index: 40;
	padding-bottom: 19em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 10.3em;
		overflow: visible;
	}
	.bks-partners {
		overflow: hidden;
		padding-bottom: 0.5em;
		@media (min-width: 768px) {
			overflow: visible;
			padding-bottom: 0;
		}

		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: -53.3%;
			margin-right: -56.3%;
			overflow-y: hidden;
			@media (min-width: 768px) {
				margin-left: -8.3%;
				margin-right: -8.3%;
				overflow: visible;
			}
		}

		&__link {
			margin-top: -17.3%;
			@include mob-bold-link;
			text-align: left;

			@media (min-width: 768px) {
				margin-top: -9.3%;
				@include bold-link;
			}
		}
	}

	.olivier {
		position: relative;
		margin-top: 13.4%;
		margin-left: 0;

		@media (min-width: 768px) {
			margin-top: 6.4%;
			margin-left: -3.7%;
		}


		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-right: 0;
			margin-bottom: 4%;
			@media (min-width: 768px) {
				margin-right: 39%;
				margin-top: 9%;
			}
		}

		&__link {
			margin-left: 0;
			width: 100%;
			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				margin-left: 3.3%;
				width: 110%;
				@include normal-link;
			}

		}
	}
}

.row-12 {
	z-index: 39;
	padding-bottom: 19em;

	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 10.6em;
		overflow: visible;
	}
	.bks-items {
		margin-bottom: 15%;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
		.bks-graph {
			margin-right: 0;
			margin-bottom: 15.5%;
			@media (min-width: 768px) {
				margin-right: 18%;
				margin-bottom: 21.5%;
			}
			&:hover .link-active {
				@include link-hover;
			}

			&__img {
				margin-left: -4.8%;
				margin-right: -4.8%;
				margin-bottom: 4.5%;

				@media (min-width: 768px) {
					margin-left: -2%;
					margin-right: 61.5%;
					margin-bottom: 6.6%;
				}

				.mob-link {
					display: block;
					@media (min-width: 768px) {
						display: none;
					}
				}

				.desk-link {
					display: none;
					@media (min-width: 768px) {
						display: inline-block;
					}
				}
			}

			&__link {
				@include mob-bold-link;
				text-align: left;
				@media (min-width: 768px) {
					@include normal-link;
				}
			}
		}
	}

	.mob-cash {
		position: relative;
		margin-left: -0.5%;
		margin-right: 45%;
		display: none;
		@media (min-width: 768px) {
			display: block;
		}
		&:hover .link-active {
			@include link-hover;
		}
		&__second {
			display: block;
			margin-top: 18em;
			margin-left: 0;
			margin-right: 0;
			@media (min-width: 768px) {
				display: none;
			}
			.mob-cash__img {
				margin-right: 0;
				margin-bottom: 6em;
			}
		}

		&__img {
			margin-right: 34.8%;
			margin-bottom: 8.8%;
		}

		&__link {
			//margin-left: 20px;
			width: 110%;
			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include normal-link;
			}
			.br-mob {
				display: none;
				@media (min-width: 768px) {
					display: block;
				}
			}
		}
	}

	.song {
		position: relative;
		margin-left: 0;
		@media (min-width: 768px) {
			margin-left: 8.4%;
		}
		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-right: -.5%;
			margin-left: -.5%;
			margin-bottom: 4%;

			@media (min-width: 768px) {
				margin-right: 0.6%;
				margin-left: 0.6%;
				margin-bottom: 8%;
			}
		}

		&__link {
			@include mob-bold-link;
			text-align: left;
			@media (min-width: 768px) {
				@include normal-link;
			}
		}
	}

	.butcher {
		position: relative;
		&__img {
			margin-top: 22.5%;
			margin-left: -14.2%;
			margin-right: -15.3%;
			@media (min-width: 768px) {
				margin-top: -35.5%;
				margin-left: -20.2%;
				margin-right: -14.3%;
			}
		}

		&__link {
			margin: -17.8% auto 0;
			width: 80%;
			@include mob-desc-text;
			line-height: 1.3;
			@media (min-width: 768px) {
				@include light-text;
				text-align: center;
				margin-top: -16.5%;
				margin-left: 8%;
				//line-height: 1.5;
			}

		}
	}
}

.row-13 {
	z-index: 38;
	padding-bottom: 37em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 7.5em;
		overflow: visible;
	}
	.marketing {
		margin-bottom: 16.5em;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-bottom: 3.5%;
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: baseline;
			justify-content: space-between;
			@media (min-width: 768px) {
				flex-direction: row;
			}
		}

		&__link {
			@include mob-bold-link;
			text-align: left;
			margin-bottom: 2%;
			@media (min-width: 768px) {
				@include normal-link;
				margin-bottom: 0;
			}
		}

		&__desc {
			@include mob-desc-text;
			@media (min-width: 768px) {
				@include light-text;
				text-align: right;
			}
		}
	}

	.sun-cake {
		position: relative;
		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			margin-left: 1.5%;
			margin-right: 1.5%;
			margin-bottom: 5.5em;
			@media (min-width: 768px) {
				margin-left: -12%;
				margin-right: -14%;
				margin-bottom: 0;
			}
		}

		&__link {
			margin: 0 auto;
			width: 80%;
			@include mob-desc-text;
			line-height: 1.3;
			@media (min-width: 768px) {
				@include normal-link;
				line-height: 1.2;
				margin-top: -10%;
				margin-left: 107%;
				width: 115%;
				text-align: left;

			}
			.br-mob {
				display: none;
				@media (min-width: 768px) {
					display: block;
				}
			}
		}
	}
}

.row-14 {
	z-index: 37;
	padding-bottom: 10.5em;
	overflow: hidden;
	@media (min-width: 768px) {
		overflow: visible;
		padding-bottom: 14em;
	}
	.icons {
		&__img {
			margin-left: -4.9%;
			margin-right: -4.9%;
		}

		&__desc {
			margin-top: -6%;
			@include mob-desc-text;
			@media (min-width: 768px) {
				@include light-text;
				text-align: center;
			}

		}
	}
}

.row-15 {
	overflow: hidden;
	padding-bottom: 14em;
	@media (min-width: 768px) {
		overflow: visible;
	}
	&__img {
		img {
			//background: radial-gradient(circle at 30px 30px, transparent 30px, white 31px) -30px -30px;

		}
	}
}

.footer {
	height: 100%;
	padding-bottom: 20em;
	@media (min-width: 768px) {
		padding-bottom: 0;
	}
	.page-info {
		//исправить позднее
		//min-height: 687px;
		padding-bottom: 107em;
		border-top: .8em solid #000;

		@media (min-width: 768px) {
			padding-bottom: 24.1%;
			padding-top: 1.4em;
			border-top: .18em solid #000;
		}
		&__desc {
			@include mob-bold-link;
			text-align: left;
			padding: 3% 0;

			@media (min-width: 768px) {
				margin-left: -1.6%;
				margin-right: -1.6%;
				width: 97%;
				@include normal-link;
				//line-height: 2.9rem;
				line-height: 1.15;
				padding: 0;
			}

			span:first-child {
				margin-bottom: 0.4em;
			}
		}

	}

	&__blocks {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 1%;
		margin-bottom: 1.7%;

		@include light-text;
		font-size: 4em;
		line-height: 1;
		border-top: none;

		@media (min-width: 768px) {
			flex-direction: row;
			border-top: .12em solid #000;

			font-size: 1.42em;
			line-height: 1.3;
			//font-size: initial;
			//line-height: initial;
		}
	}

	&__year {
		margin-bottom: 0.35em;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
	}

	&__dev {
		display: none;

		@media (min-width: 768px) {
			display: inline-block;
			margin-left: 21.5em;

			span {
				display: inline;
			}
		}
	}

	&__links, &__dev {
		//@include light-text;
		letter-spacing: 0;

		.link-active {
			margin-right: 0.58em;
			@media (min-width: 768px) {
				margin-right: .8em;
			}
			&:last-child {
				margin-right: 0;
			}
			&__inst {
				display: none;
				@media (min-width: 768px) {
					display: inline-block;
				}
			}
		}
		.link-lang {
			margin-left: 2em;
			margin-right: 0;
		}
		.link-margin {
			margin-right: 0;
		}
	}

	&__dash-mob {
		display: inline-block;
		letter-spacing: -0.145em;
		@media (min-width: 768px) {
			display: none;
		}
	}
}
